body, html{
    /* background-image: url('/src/images/image4.png'); */
    height: 100%;
    width: 100%;
    background-repeat: no-repeat;
    background-attachment: fixed;
}
.navbar-dark{
    position: fixed;
    z-index: 100;
    width: 100%;
    background-color: rgb(31, 35, 37);
    height: 60px;
}
.navBarClass{
    font-size: 40px;
    font-weight: bold;
}
.navbar-dark li{
    font-size: 20px;
}
.navbar-dark li :hover{
    font-weight: bolder;
}
#navbarLogin{
    background-color: rgb(24, 139, 234);
    border-radius: 15px;
    margin: 2px;
}
#loginReg{
    color: rgb(201, 160, 198);
}
.navbar-brand{
    font-size: 25px;
}