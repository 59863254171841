.login{
    justify-content: center;
}
input{
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 3px;
    width: 100%;

}


.button :hover{
    background-color: rgb(215, 222, 215);
}

#log{
    width: 100px;
    justify-content: center;
    margin-left: 40%;
    background-color: rgb(0, 255, 38);
}
