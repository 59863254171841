
.home{
    position: relative;
    background-color: rgb(11, 8, 8);
    opacity: 0.9;
    height: 100%;  
}
.home-about{
    position:relative;
    justify-content: center;
    justify-items: center;
    align-items: center;
    width: 100%;
    max-height: 500px;
}
.home-about{
    resize: both;
    overflow: hidden;
    border: 1px solid red;
    z-index: 1;
}
.home-about li{
    display: flex;
    position: relative;
    list-style:circle;
    justify-content: center;
    padding: 10px;
    align-items: center;
    display:block;
}
.homeTopic{
    color: rgb(218, 223, 226);
}
#myVideo{
    width: 100%;
}
.latestInfo{
    margin-top: 90px;
    background-color: rgb(198, 212, 170);
    width: 80%;
    padding: 30px;
}

.joinPage{
    position: relative;
    display: flex;
    justify-content: center;
      
}
.joinPage button{
    background-color: rgb(21, 213, 91);
    font-size: 30px;
    margin: 20px;
    border-radius: 15%;
}
.joinParag{
    color: blueviolet;
    display: flex;
    justify-content: center;
}
.joinPage li{
    list-style: none;
}
/*  */
#cardImage{
    width: 50%;
    height: 70px;
    border-radius: 10%;
}
#logoImage{
    border-radius: 25px;
    width: 40px;
    height: 40px; 
}
#algoImage{
    width: 90%;
}
#dataImage{
    width: 90%;
}

.grid-span-2{
    grid-column: span 2;
}
@media (min-width: 40em){
    .grid{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 1.5rem;
        padding-block: 2rem;
        width: min(95%, 70rem);
        margin-inline: auto;

    }
}
.grids{
    background-color: rgb(223, 227, 232);
    padding: 10px;
    border-radius: 5px;
}

/* .grid :last-child{
    grid-column: 4;
} */

.grid-span-2{
    background-color: rgb(98, 255, 0);
}
.grid ul li{
    list-style: none;
    display: inline-block;
    padding: 0.5%;
    margin: 0.5%;
}


