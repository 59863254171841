.modal-container{
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgb(45, 24, 2);
    opacity: 0.95;
    margin: 0px;
}
.medal{
    border-radius: 5px;
    background-color: rgb(218, 213, 217);
    padding: 0rem 1rem 1rem 1rem;
    width: 50%;
}

.btn-submit{
    background-color: #42a942;
}

.modal-header{
    display: flex;
    justify-content: flex-end;
}
.modal-header a{
    font-size: 40px;
    padding-top: 0px;
    padding-bottom: 0px;
}
.modal-content{
    margin-bottom: 2rem;
    display: inline-block;
    justify-content: center;
    align-items: center;
}
.modal-footer{
    display: flex;
    justify-content: center;
    background-color: red;
}


