
.portoContainer{
    display: inline-flex;
    background: rgb(33, 36, 36); 
    width: 100%;
    max-height: 100%;
    min-height: 100vh;
    opacity: 0.9;
    align-items: center;
}
.checkDiv{
    margin-top: 60px;
    z-index: 1;
    justify-content: center;
    justify-items: center;
    align-items: center;
}

.portofolioPhoto{
    max-width: 260px;
    max-height: 320px;
    min-width: 30%;
    min-height: 40px;
    margin: 5%;
}
.portoDiscription{
    display: flex;
    position: relative;
    min-width: 80%;
    max-width: 1100px;
    padding: 5%;
    background-color: bisque;
    border-radius: 10px;
    justify-content: center;
    justify-items: center;
}

#portoImage{
    width: 80%;
    height: 80%;
    border-radius: 5%;
    justify-content: center;
}
.portPara{
    width: 60%;
}