
.post-content{
    padding-top: 80px;
    background-color: black;
    opacity: .9;
    min-height: 100vh;
    
}
.post-container{
    position: relative;
    display: flex;
    justify-content: center;
    justify-items: center;
}
.postMessage{
    color: yellow;
}
.reactQuill{
    background-color: aliceblue;
    width: 100%;
    margin: 5px;
    justify-content: center;
    justify-items: center;
}
.ql-editor{
    min-height: 300px;
}
.postButton{
    position: relative;
    display: flex;
    justify-content: center;
    justify-items: center;
    align-items: center;
}
.postButton button{
    width: 80px;
    height: 50px;
    margin: 10px;
    font-size: 30px;
    padding: 5px;
    background-color: greenyellow;
    border-radius: 10%;
}
#textButton{
    justify-items: center;
    margin: 10px;
    border-radius: 17%;
}

