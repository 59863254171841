.container{
    /* background-color: rgb(168, 59, 59); */
    opacity: .9;
    position: fixed;
    height: 100vh;
}
.content{
    /* background-color: rgb(95, 154, 91); */
    opacity: .9;
    height: 800px;
}
.content-container{
    padding: 80px;
    background-color: rgb(21, 20, 21);
    opacity: .9;
    width: 100%
}

.username{
    font-size: 20px;
    color:blue;
}
.date{
    font-size: 10px;
    color: rgb(61, 60, 60);
}
.post-contents{
    background-color: wheat;
    margin: 20px;
    width: 90%;
    border-radius: 4px;
}
.post-text{
    border-style: ridge;
    padding: 10px;
    border-radius: 3px;
    word-break: break-word;
    margin: 5px;
}
.replayButton{
    display: flex;
    align-items: center;
    justify-content: end;
    margin-right: 15%;
    
}
.replayButton:not(:last-child){
    margin-right: 20px;
}
#comment{
    background-color: blue;
    font-size: 25px;
    border-radius: 5px;
}

#dislike{
    background-color: rgb(49, 49, 50);
    font-size: 25px;
    border-radius: 5px;
}

#like{
    background-color: blue;
    font-size: 25px;
    border-radius: 5px;
}
.content-grid{
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 1.5rem;
    padding-block: 2rem;
    width: min(95%, 70rem);
    margin-inline: auto;

}