.join{
    display: flex;
    align-items: center;
    justify-content: center;
}
.modalForm{
    border: solid;
    padding: 50px;
}


.joinButton :hover{
    background-color: rgb(37, 162, 70);
}


#login{
   width: 100%;
   background-color: rgb(14, 208, 219);
   margin-bottom: 10px;
   margin-top: 10px;
}

#signup{
   width: 100%;
   background-color: blanchedalmond;
}

#login :hover{
    background-color: yellowgreen;
}

#signup :hover{
    background-color: rgb(50, 205, 133);
}